import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext';
import './initiatives.css'
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import { HomeProject } from './MultiCarousel';
import UndrLineText1 from './underline/UndrLineText1';
import { Helmet } from 'react-helmet';

const englishData = {
  section1: {
    title: 'Initiatives',
  },
  section2: {
    text: ['<p >Thriving Youth, Abundant Farming, Sustainable Future! The path to a Self-Reliant India with <span style="color:#EA5136; font-weight:700">Tejash Patel</span> !</p>'],
    img: `${require('../images/InitiativesTop.jpg')}`
  },
  section3: [
    {
      title: 'Empowering Youth through Atmanirbhar Rojgar Yojana : ',
      text :[
        "The bank aims to provide employment to 24,000 young individuals in 1200 villages under its Atmanirbhar Rojgar Yojana.",
        "This initiative supports the vision of an Atmanirbhar Bharat by empowering the youth and promoting entrepreneurship."
      ],
      Employements1: "24000",
      Employements2: "1200",
      Employements1Text : 'Employements',
      Employements2Text :'Villages',
      Background: '#EA5136',
      color: 'white'
    },
    {
      title: 'Solar Rooftop Yojana for Sustainable Energy :',
      text :[
        "A special pilot project under the Pradhan Mantri Suryoday Yojana will be implemented in 1200 villages.",
        "This initiative aims to provide the benefits of the Solar Rooftop Yojana to every village, promoting sustainable energy practices."
      ],
      Employements1: "1100",
      Employements2: "1000000",
      Employements1Text :'Villages',
      Employements2Text : ' kwh/Month  ',
      Background: '#727CB6',
      color: 'white'
    },
    {
      title: "Kisan Credit Card for Financial Inclusion :",
      text :[
        "Under the government's Ghar-Ghar Kisan Credit Card Abhiyan, the bank has decided to provide Kisan Credit Cards to all land-owning farmers at their doorstep.",
        "This will  ensure financial inclusion and empower farmers to access formal credit for  their agricultural needs."
      ],
      Employements1: "",
      Employements2: "",
      Employements1Text :'',
      Employements2Text : '',
      Background: '#FBF7A9',
      color: '#1d2b77'
    },
    {
      title: "Supporting Landless Farmers in Animal Husbandry :",
      text :[
        "A significant decision has been taken to include landless farmers engaged in animal husbandry under the Pashudhan Kisan Credit Card scheme.",
        "This will provide financial assistance to landless farmers and promote the growth of the animal husbandry sector."
      ],
      Employements1: "",
      Employements2: "",
      Employements1Text :'',
      Employements2Text : '',
      Background: '#EECF99',
      color: '#1d2b77'
    },
  ],
  section4: {
    title: 'CURRENT PROJECTS INTIATIVES TO PARTICIPATE',
    project: [
      {
        title: 'Project Category',
        text: 'Help to Sustain by green energy project',
        img: `${require('../images/ps1.jpg')}`,
        url: './',
        button: 'Donate'
      },
      {
        title: 'Project Category',
        text: 'Help to Sustain by green energy project',
        img: `${require('../images/ps2.jpg')}`,
        url: './',
        button: 'Donate'

      },
      {
        title: 'Project Category',
        text: 'Help to Sustain by green energy project',
        img: `${require('../images/ps3.jpg')}`,
        url: './',
        button: 'Donate'

      },
      {
        title: 'Project Category',
        text: 'Help to Sustain by green energy project',
        img: `${require('../images/ps4.jpg')}`,
        url: './',
        button: 'Donate'

      },
      {
        title: 'Project Category',
        text: 'Help to Sustain by green energy project',
        img: `${require('../images/ps2.jpg')}`,
        url: './',
        button: 'Donate'

      },
    ]
  },
  section5: {
    title: 'Testimonial',
    data: [
      {
        name: 'Kristin Watson',
        post: 'Marketing Coardinator',
        text: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'Jerome Bell',
        post: 'Dog Trainer',
        text: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'Jane Cooper',
        post: 'President of Sales',
        text: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'Kristin Watson',
        post: 'Marketing Coardinator',
        text: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'Jerome Bell',
        post: 'Dog Trainer',
        text: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        img: `${require('../images/g3.jpg')}`
      },
    ]
  },
  section6: {
    img: `${require('../images/Initiatives2.png')}`,
    title: 'Why Choose Us',
    points: [
      {
        // img: `${require('../images/InitiativesIcon1.png')}`,
        text: 'Thriving  Youth & Sustainable Future says Tejas Patel with spearheads bank programs  for jobs and solar power, building a self-reliant India.  '
      },
      {
        // img: `${require('../images/InitiativesIcon2.png')}`,
        text: "Tejash   Patel Empowered Farmers, Stronger Communities through Bank's Kisan Credit Cards  and village-based solar initiatives drive financial inclusion and a thriving  rural India."
      },
      {
        // img: `${require('../images/InitiativesIcon3.png')}`,
        text:  "Tejash Patel Breaking Barriers, Building Prosperity for No land, no problem. Every farmer gets the financial tools to thrive in a sustainable future. "
      }
    ]

  }
}

const gujratData = {
  section1: {
    title: 'પહેલ',
  },
  section2: {
    text: ['<p>ફળતી યુવાની, વિપુલ ખેતી, ટકાઉ ભવિષ્ય! <span style="color:#EA5136">તેજશ પટેલ</span> સાથે આત્મનિર્ભર ભારતનો માર્ગ !</p>'],
    img: `${require('../images/InitiativesTop.jpg')}`
  },
  section3 : [
    {
      title: 'આત્મનિર્ભર રોજગાર યોજના દ્વારા યુવા સશક્તિકરણ :',
      text :[
        "બેંક તેની આત્મનિર્ભર રોજગાર યોજના હેઠળ 1200 ગામડાઓમાં 24,000 યુવાનોને રોજગાર આપવાનું લક્ષ્ય રાખે છે.",
        "આ પહેલ યુવાનોને સશક્તિકરણ કરીને અને ઉદ્યોગસાહસિકતાને પ્રોત્સાહન આપીને આત્મનિર્ભર ભારતના વિઝનને સમર્થન આપે છે."
      ],
      Employements1 : "24000",
      Employements2: "1200",
      Employements1Text : 'રોજગાર',
      Employements2Text :'ગામડાઓ',
      Background: '#EA5136',
      color: '#ffffff'
    },
    {
      title: 'સસ્ટેનેબલ એનર્જી માટે સોલાર રૂફટોપ યોજના:',
      text :[
        "પ્રધાનમંત્રી સૂર્યોદય યોજના હેઠળ એક ખાસ પાયલોટ પ્રોજેક્ટ 1200 ગામોમાં લાગુ કરવામાં આવશે.",
        "આ પહેલનો ઉદ્દેશ્ય દરેક ગામને સૌર રૂફટોપ યોજનાના લાભો આપવાનો છે, ટકાઉ ઉર્જા પ્રથાઓને પ્રોત્સાહન આપવું."
      ],
      Employements1: "1100",
      Employements2 : "1000000",
      Employements1Text :'ગામડાઓ',
      Employements2Text : 'kwh/મહિનો',
      Background: '#727CB6',
      color: '#ffffff'
    },
    {
      title: "નાણાકીય સમાવેશ માટે કિસાન ક્રેડિટ કાર્ડ :",
      text :[
        "સરકારના ઘર-ઘર કિસાન ક્રેડિટ કાર્ડ અભિયાન હેઠળ, બેંકે તમામ જમીન માલિક ખેડૂતોને તેમના ઘરઆંગણે કિસાન ક્રેડિટ કાર્ડ આપવાનું નક્કી કર્યું છે.",
        "આ નાણાકીય સમાવેશને સુનિશ્ચિત કરશે અને ખેડૂતોને તેમની કૃષિ જરૂરિયાતો માટે ઔપચારિક ધિરાણ મેળવવા માટે સશક્ત બનાવશે."
      ],
      Employements1: "",
      Employements2: "",
      Employements1Text :'',
      Employements2Text : '',
      Background: '#FBF7A9',
      color: '#000000'
    },
    {
      title: "પશુપાલનમાં ભૂમિહીન ખેડૂતોને સહાયતા :",
      text :[
        "પશુધન કિસાન ક્રેડિટ કાર્ડ યોજના હેઠળ પશુપાલનમાં રોકાયેલા ભૂમિહીન ખેડૂતોને સમાવવા માટે એક મહત્વપૂર્ણ નિર્ણય લેવામાં આવ્યો છે.",
        "આનાથી ભૂમિહીન ખેડૂતોને નાણાકીય સહાય મળશે અને પશુપાલન ક્ષેત્રના વિકાસને પ્રોત્સાહન મળશે."
      ],
      Employements1: "",
      Employements2: "",
      Employements1Text :'',
      Employements2Text : '',
      Background: '#EECF99',
      color: '#000000'
    },
  ],
 
  section4: {
    title: 'ભાગ લેવા માટે વર્તમાન પ્રોજેક્ટ્સ પહેલ',
    project: [
      {
        title: 'પ્રોજેક્ટ શ્રેણી',
        text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
        img: `${require('../images/ps1.jpg')}`,
        url: './',
        button: 'દાન કરો'
      },
      {
        title: 'પ્રોજેક્ટ શ્રેણી',
        text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
        img: `${require('../images/ps2.jpg')}`,
        url: './',
        button: 'દાન કરો'

      },
      {
        title: 'પ્રોજેક્ટ શ્રેણી',
        text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
        img: `${require('../images/ps3.jpg')}`,
        url: './',
        button: 'દાન કરો'

      },
      {
        title: 'પ્રોજેક્ટ શ્રેણી',
        text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
        img: `${require('../images/ps4.jpg')}`,
        url: './',
        button: 'દાન કરો'

      },
      {
        title: 'પ્રોજેક્ટ શ્રેણી',
        text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
        img: `${require('../images/ps2.jpg')}`,
        url: './',
        button: 'દાન કરો'

      },
    ]
  },
  section5: {
    title: 'પ્રશંસાપત્ર',
    data: [
      {
        name: 'ક્રિસ્ટિન વોટસન',
        post: 'માર્કેટિંગ કોર્ડીનેટર',
        text: 'સાચી વાત તો એ છે કે દર્દ બહુ જરૂરી છે, અને ભણતરનું પાલન થશે, પણ એવા સમયે થાય છે કે કામ અને પીડા ઘણી હોય છે. નાની વિગતમાં આવવું, આપણું કોણ છે?',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'જેરોમ બેલ',
        post: 'ડોગ ટ્રેનર',
        text: 'સાચી વાત તો એ છે કે દર્દ બહુ જરૂરી છે, અને ભણતરનું પાલન થશે, પણ એવા સમયે થાય છે કે કામ અને પીડા ઘણી હોય છે. નાની વિગતમાં આવવું, આપણું કોણ છે?',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'જેન કૂપર',
        post: 'સેલ્સ પ્રમુખ',
        text: 'સાચી વાત તો એ છે કે દર્દ બહુ જરૂરી છે, અને ભણતરનું પાલન થશે, પણ એવા સમયે થાય છે કે કામ અને પીડા ઘણી હોય છે. નાની વિગતમાં આવવું, આપણું કોણ છે?',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'ક્રિસ્ટિન વોટસન',
        post: 'માર્કેટિંગ કોર્ડીનેટર',
        text: 'સાચી વાત તો એ છે કે દર્દ બહુ જરૂરી છે, અને ભણતરનું પાલન થશે, પણ એવા સમયે થાય છે કે કામ અને પીડા ઘણી હોય છે. નાની વિગતમાં આવવું, આપણું કોણ છે?',
        img: `${require('../images/g3.jpg')}`
      },
      {
        name: 'જેરોમ બેલ',
        post: 'ડોગ ટ્રેનર',
        text: 'સાચી વાત તો એ છે કે દર્દ બહુ જરૂરી છે, અને ભણતરનું પાલન થશે, પણ એવા સમયે થાય છે કે કામ અને પીડા ઘણી હોય છે. નાની વિગતમાં આવવું, આપણું કોણ છે?',
        img: `${require('../images/g3.jpg')}`
      },
    ]
  },
  section6: {
    img: `${require('../images/Initiatives2.png')}`,
    title: 'શા માટે અમને પસંદ કરો',
    points: [
      {
        img: `${require('../images/InitiativesIcon1.png')}`,
        text: '  તેજશ પટેલના નેતૃત્વમાં યુવાનોનું શશક્તિકરણ, ઉજ્વળ ભવિષ્ય માટે  બેંકના કાર્યક્રમો રોજગારી અને સોલાર ઊર્જાને પ્રોત્સાહન આપીને આત્મનિર્ભર ભારતના નિર્માણ તરફ દોરી રહ્યા છે.'
      },
      {
        img: `${require('../images/InitiativesIcon2.png')}`,
        text: 'તેજશ પટેલના માર્ગદર્શન હેઠળ, બેંકના કિસાન ક્રેડિટ કાર્ડ અને ગામ સોલાર આધારિત  પહેલો દ્વારા ખેડૂતોનું શશક્તિકરણ અને સમૃદ્ધ સમુદાયોનું નિર્માણ થઈ રહ્યું છે.'
      },
      {
        img: `${require('../images/InitiativesIcon3.png')}`,
        text: 'જમીન ન હોય તો ચિંતા નહીં, દરેક ખેડૂતને સારા ભવિષ્ય માટે નાણાકીય સાધનો મળશે એ તેજશ પટેલની દ્રષ્ટિ છે જે  બેંકની યોજના દ્વારા ખેડૂતોને આગળ વધવામાં મદદરૂપ થશે .'
      }
    ]

  }
}

const Initiatives = () => {

  const { language } = useLanguage();
  const [initiativesData, setInitiativesData] = useState()
  const [counterOn, setCounterOn] = useState(false)



  useEffect(() => {
    AOS.init({ duration: 1000 });
  })

  useEffect(() => {
    if (language) {
      setInitiativesData(englishData)
    } else {
      setInitiativesData(gujratData)
    }
  }, [language])

  const navigate = useNavigate()


  const project = () => {
    let arr = []
    for (let i = 0; i < initiativesData?.section4?.project?.length; i++) {
      arr.push(
        <div class='projectCart'>
          <div class='projectCartImg'>
            <img src={initiativesData?.section4?.project?.[i].img} style={{ width: '100%' }} alt="" />
          </div>
          <div class='projectCartText bg-white py-3'>
            <p class=''>{initiativesData?.section4?.project?.[i].title}</p>
            <p class='fs-5 fw-bold col-11 m-auto'>{initiativesData?.section4?.project?.[i].text}</p>
          </div>
          <div class='projectCartBtn'>
            <button class="custom-btn btnBlue" onClick={() => navigate('/projects-details')} style={{ width: '100%' }}><span>{initiativesData?.section4?.project?.[i].button}</span></button>
          </div>
        </div>
      )
    }
    return arr;
  }

  const testimonial = () => {
    let arr = []
    for (let i = 0; i < initiativesData?.section5?.data?.length; i++) {
      arr.push(
        <div class='bg-white' style={{ boxShadow: '0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11)' }}>
          <div style={{ width: '20%', height: '3px', background: '#1D2344' }}></div>
          <div class='col-11 m-auto text-start my-3'>
            <p>{initiativesData?.section5?.data?.[i]?.text}</p>
          </div>
          <div class='d-flex gap-3 p-3 text-white' style={{ background: '#EA5136' }}>
            <div style={{ width: '50px', height: '50px', borderRadius: '50%' }}>
              <img src={initiativesData?.section5?.data?.[i].img} style={{ width: '100%', borderRadius: '50%' }} alt="" />
            </div>
            <div class='text-start text-white'>
              <p class='fw-bold text-white'>{initiativesData?.section5?.data?.[i].name}</p>
              <p class='text-white' style={{ fontSize: '14px' }}>{initiativesData?.section5?.data?.[i].post}</p>

            </div>

          </div>

        </div>
      )
    }
    return arr;
  }


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tejash Patel | Official</title>
        <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
        <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
      </Helmet>
      <div class='blueBg' >
        <div>
          <p class='display-1 fw-bold experienceText text-white' data-aos="zoom-in" dangerouslySetInnerHTML={{ __html: initiativesData?.section1?.title }}></p>
        </div>
      </div>

      <div class='col-11 m-auto row align-items-center'>
        <div class='col-md-6'>
          <div class='col-11 m-auto text-start'>
            {
              initiativesData?.section2?.text?.map((el) => {
                return (
                  <p class='mt-3 fs-2 fw-bold' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: el }}></p>
                )
              })
            }
          </div>

        </div>
        <div class='col-md-6'>
          <div class='col-lg-8 col-11 m-auto text-start my-4'>
            <img class='rounded-4' data-aos="zoom-in" src={initiativesData?.section2?.img} style={{ width: '100%' }} alt="" />
          </div>
        </div>

      </div>

      <div class='initiativesCartDiv'>
        {
          initiativesData?.section3?.map((el, i) => {
            return (
              <div class='col-11 m-auto initiativesCart rounded-3  mt-3 row text-start align-items-center' style={{ background: `${el.Background}`, top: `${(i + 1) * 12}%`, color: `${el.color}` }}>
                <div class='col-md-6'>
                  <div class='col-11 m-auto'>
                    <p class='h4 mt-3'>{el.title}</p>
                    {
                      el.text?.map((el)=>{
                        return(
                          <p class='mt-3'>{el}</p>
                        )
                      })
                    }
                  </div>
                </div>
                <div class='col-md-6 text-center'>
                    {
                      el.Employements1 == "" ? " " : 
                  <p class='h1'>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='display-3 fw-bold' style={{ color: `${el.color}` }}><span  >{counterOn && <CountUp start={0} end={el.Employements1} delay={0} />}</span>+ <span class='fs-5'>{el.Employements1Text}</span></p>
                    </ScrollTrigger>
                  </p>
                    }
                     {
                      el.Employements1 == "" ? " " : 
                  <p class='h1'>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='display-3 fw-bold' style={{ color: `${el.color}` }}><span  >{counterOn && <CountUp start={0} end={el.Employements2} delay={0} />}</span>+  <span class='fs-5'>{el.Employements2Text}</span></p>
                    </ScrollTrigger>
                  </p>
                  }
                </div>
              </div>
            )
          })
        }
      </div>

      <div class='bg-white mt-5'>
        <br />
        <div>
          <p class='fs-4 fw-bold my-4' data-aos="fade-down" style={{ color: '#727CB6' }}>{initiativesData?.section4?.title}</p>
          <div class='col-11 m-auto my-4'>
            <HomeProject data={project()} />
          </div>
        </div>
        <br />
        <div class='my-5 '>
          <UndrLineText1 text={initiativesData?.section5?.title} />
          <br />
          <div class='col-11 m-auto'>
            <HomeProject data={testimonial()} />
          </div>
        </div>
        <br />

      </div>

      <div class='row m-0 p-0 align-items-center' style={{ background: "#EECF99" }}>
        <div class='col-md-6 p-0'>
          <div class='col-10 m-auto' data-aos="fade-right">
            <img src={initiativesData?.section6?.img} style={{ width: '100%' }} alt="" />
          </div>
        </div>
        <div class='col-md-6'>
          <br />
          <div>
            {
              initiativesData?.section6?.points.map((el) => {
                return (
                  <div class='d-flex gap-3 mt-3 rounded-5 p-3 align-items-center text-white' style={{  backgroundImage: 'linear-gradient(to right,  #EA5136 0% ,#EA5136 50%, rgba(65,72,116,0.1))' }} data-aos="fade-up" >
                    {/* <div style={{ width: '50px', height: '50px' }}>
                      <img src={el.img} width={'100%'} alt="" />
                    </div> */}
                    <div>
                      <p>{el.text}</p>
                    </div>

                  </div>
                )
              })
            }
          </div>
          <br />
        </div>

      </div>


    </div>
  )
}

export default Initiatives