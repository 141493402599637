import React, { useEffect, useRef, useState } from 'react'
import './home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPlayer from 'react-player';
import { HomeProject, HomeTop } from '../MultiCarousel';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import { useLanguage } from '../LanguageContext';
import UndrLineText1 from '../underline/UndrLineText1';
import UndrLineText2 from '../underline/UndrLineText2';
import UndrLineText3 from '../underline/UndrLineText3';
import TimeLine2 from './TimeLine2';
import Text from '../AnimatedText';
import AnimatedText from '../AnimatedText';
import Signature from './Signature';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SocialMediaShare from '../SocialMediaShare ';
import InstagramEmbed from '../InstagramEmbed';
import axios from 'axios';
import md5 from 'md5';
import ImageGallery from './ImageGallery';




const englishData = {
    section1: {
        title1: 'Leader of',
        title2: 'Co-Operative,',
        // title3: 'Nadiad',
    },
    section2: [
        {
            icon: `${require('../../images/agriculturalIcon.png')}`,
            title: 'Lorem Ipsum',
            text: 'Agricultural Visionary'
        },
        {
            icon: `${require('../../images/leadericon.png')}`,
            title: 'Lorem Ipsum',
            text: 'Innovative Leader'
        },
        {
            icon: `${require('../../images/farmerIcon.png')}`,
            title: 'Lorem Ipsum',
            text: 'Empowering Farmer'
        }
    ],
    section3: {
        line1: "Farming progress,",
        line2_1: "bright future with ",
        line2_2: " Tejash Patel",
        // line3_1: "the taste of",
        // line3_2: "Farmers ",
        line4: "orem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt uttpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ",
        button1: "Learn More",
        button2: "Explore Projects",
    },
    section4: {
        title: 'Our Projects',
        project: [
            {
                title: 'KDCC Bank Project',
                text: "Soaring to New Heights under Tejas Patel's Leadership",
                img: `${require('../../images/ps4.jpg')}`,
                url: './kdcc-bank-project',
                button: 'Learn More'
            },
            {
                title: 'Sauraurja',
                text: 'Brings clean energy independence and cost reduction to villages',
                img: `${require('../../images/ps1.jpg')}`,
                url: './sauraurja',
                button: 'Learn More'

            },
            // {
            //     title: 'Project Category',
            //     text: 'Help to Sustain by green energy project',
            //     img: `${require('../../images/ps3.jpg')}`,
            //     url: './',
            //     button: 'Learn More'

            // },
            // {
            //     title: 'Project Category',
            //     text: 'Help to Sustain by green energy project',
            //     img: `${require('../../images/ps4.jpg')}`,
            //     url: './',
            //     button: 'Learn More'

            // },
            // {
            //     title: 'Project Category',
            //     text: 'Help to Sustain by green energy project',
            //     img: `${require('../../images/ps2.jpg')}`,
            //     url: './',
            //     button: 'Learn More'

            // },
        ]
    },
    section5: {
        title1: 'Awards And',
        title2: 'Achivements',
        timeLine: {
            one: {
                year: "2024 - Present",
                text: 'The Kheda Dist. Central Co-op. Bank Ltd. (KDCC Bank)',
                img: `${require('../../images/tlYear24.jpg')}`
            },
            two: {
                year: "2018 - Present",
                text: 'The Petlad-Sojatra Taluka Solar-Energy Manufacturer Co-operative Society Ltd.',
                img: `${require('../../images/tlYear18.jpg')}`

            },
            three: {
                year: "2017 - Present",
                text: 'The Gujarat State Co Operative Tobacco Growers Federation Ltd.',
                img: `${require('../../images/tlYear17.jpg')}`

            },
            four: {
                year: "2015 - Present",
                text: 'The Petlad Taluka Farm Development Industry Service Co-Operative Society Ltd.',
                img: `${require('../../images/tlYear15.jpg')}`

            },
        },
        button: 'Explore Achivements'
    },
    section6: [
        {
            number: '12',
            title: 'project'
        },
        {
            number: '359',
            title: 'Events'
        },
        {
            number: '98',
            title: 'Programs'
        },
        {
            number: '121',
            title: 'Programs'
        },
    ],
    section7: {
        title: 'Our Gallery',
        photos: [
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 150 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 150 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 200 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 400 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 300 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 200 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 200 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 400 },
        ],
        button: 'View More'
    },
    section8: {
        title: 'Our Media & News',
        news: [
            {
                title: "KDCC Bank holds discussions ",
                desc: "KDCC Bank holds discussions with American delegation Decision to establish Indo-American Cooperative Bank in Pennsylvania",

                img: `${require('../../images/news1.jpeg')}`,
                id: 'news1'
            },
            {
                title: "Petlad Agricultural Produce Market Committee  ",
                desc: "KDCC Bank holds discussions with American delegation Decision to establish Indo-American Cooperative Bank in Pennsylvania",
                img: `${require('../../images/news2.jpg')}`,
                id: 'news2'
            },
            {
                title: "Gujarat Niyantrit Bazar Sangh Tejas Patel",
                desc: "KDCC Bank holds discussions with American delegation Decision to establish Indo-American Cooperative Bank in Pennsylvania",
                img: `${require('../../images/news3.jpg')}`,
                id: 'news3'
            },
            {
                title: "Kheda District Central Co-operative Bank",
                desc: "KDCC Bank holds discussions with American delegation Decision to establish Indo-American Cooperative Bank in Pennsylvania",
                img: `${require('../../images/news4.jpeg')}`,
                id: 'news4'
            },
        ]
    }


}

const gujratData = {
    section1: {
        title1: 'સહકરીતાના',
        title2: 'સુમેરુ',
        // title3: 'નડિયાદ',
    },
    section2: [
        {
            icon: `${require('../../images/agriculturalIcon.png')}`,
            title: 'લોરેમ ઇપ્સમ',
            text: 'કૃષિ દીર્ઘદ્રષ્ટા'
        },
        {
            icon: `${require('../../images/leadericon.png')}`,
            title: 'લોરેમ ઇપ્સમ',
            text: 'નવીનીકરણના પ્રણેતા'
        },
        {
            icon: `${require('../../images/farmerIcon.png')}`,
            title: 'લોરેમ ઇપ્સમ',
            text: 'ખેડૂત સશક્તિકરણ'
        }
    ],
    section3: {
        line1: "તેજસ પટેલ ની સાથે",
        line2_1: "ખેતીની પ્રગતિ,",
        line2_2: "ઉજ્જવલ ભવિષ્ય",
        // line3_1: "નો સ્વાદ",
        // line3_2: "ખેડૂતો ",
        line4: "કૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરીકૃષિ વિઝનરી ",
        button1: "વધુ જાણો",
        button2: "પ્રોજેક્ટ્સનું અન્વેષણ કરો",
    },
    section4: {
        title: 'અમારા પ્રોજેક્ટ્સ',
        project: [
            {
                title: 'કેડીસીસી બેંક',
                text: 'તેજસ પટેલના નેતૃત્વમાં નવી ઊંચાઈઓ સુધી પહોંચે છે',
                img: `${require('../../images/ps4.jpg')}`,
                url: './kdcc-bank-project',
                button: 'વધુ જાણો',
            },
            {
                title: 'સૌર ઊર્જા',
                text: 'ગામડાઓમાં સ્વચ્છ ઉર્જા સ્વતંત્રતા અને ખર્ચમાં ઘટાડો લાવે છે',
                img: `${require('../../images/ps1.jpg')}`,
                url: './sauraurja',
                button: 'વધુ જાણો',
            },
            // {
            //     title: 'પ્રોજેક્ટ શ્રેણી',
            //     text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
            //     img: `${require('../../images/ps3.jpg')}`,
            //     url: './',
            //     button: 'વધુ જાણો',
            // },
            // {
            //     title: 'પ્રોજેક્ટ શ્રેણી',
            //     text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
            //     img: `${require('../../images/ps4.jpg')}`,
            //     url: './',
            //     button: 'વધુ જાણો',
            // },
            // {
            //     title: 'પ્રોજેક્ટ શ્રેણી',
            //     text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
            //     img: `${require('../../images/ps2.jpg')}`,
            //     url: './',
            //     button: 'વધુ જાણો',
            // },
        ]
    },
    section5: {
        title1: 'પુરસ્કારો અને',
        title2: 'સિદ્ધિઓ',
        timeLine: {
            one: {
                year: "2024 - વર્તમાન",
                text: 'ખેડા જિ. સેન્ટ્રલ કો-ઓપ. બેંક લિ. (KDCC બેંક)',
                img: `${require('../../images/tlYear24.jpg')}`
            },
            two: {
                year: "2018 - વર્તમાન",
                text: 'પેટલાદ-સોજાત્રા તાલુકા સોલાર-એનર્જી ઉત્પાદક સહકારી મંડળી લિ.',
                img: `${require('../../images/tlYear18.jpg')}`

            },
            three: {
                year: "2017 - વર્તમાન",
                text: 'ગુજરાત સ્ટેટ કો ઓપરેટિવ ટોબેકો ગ્રોવર્સ ફેડરેશન લિ.',
                img: `${require('../../images/tlYear17.jpg')}`

            },
            four: {
                year: "2015 - વર્તમાન",
                text: 'પેટલાદ તાલુકા ફાર્મ ડેવલપમેન્ટ ઈન્ડસ્ટ્રી સર્વિસ કો-ઓપરેટિવ સોસાયટી લિ.',
                img: `${require('../../images/tlYear15.jpg')}`

            },

        },
        button: 'સિદ્ધિઓનું અન્વેષણ કરો'
    },
    section6: [
        {
            number: '12',
            title: 'પ્રોજેક્ટ'
        },
        {
            number: '359',
            title: 'ઘટનાઓ'
        },
        {
            number: '98',
            title: 'કાર્યક્રમો'
        },
        {
            number: '121',
            title: 'કાર્યક્રમો'
        },
    ],
    section7: {
        title: 'અમારી ગેલેરી',
        photos: [
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 150 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 150 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 200 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 400 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 300 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 200 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 200 },
            { src: `${require('../../images/tlYear24.jpg')}`, width: 300, height: 400 },
        ],
        button: 'વધુ જોવો'
    },
    section8: {
        title: 'અમારા મીડિયા અને સમાચાર',
        news: [
            {
                title: "કેડીસીસી બેંકે અમેરિકન પ્રતિનિધિમંડળ",
                desc: "કેડીસીસી બેંકે અમેરિકન પ્રતિનિધિમંડળ સાથે ચર્ચા કરી પેન્સિલવેનિયામાં ઈન્ડો-અમેરિકન કોઓપરેટિવ બેંકની સ્થાપના કરવાનો નિર્ણય",
                img: `${require('../../images/news1.jpeg')}`,
                id: 'news1'
            },
            {
                title: "પેટલાદ ખેતીવાડી ઉત્પન્ન બજાર સમિતિ",
                desc: "કેડીસીસી બેંકે અમેરિકન પ્રતિનિધિમંડળ સાથે ચર્ચા કરી પેન્સિલવેનિયામાં ઈન્ડો-અમેરિકન કોઓપરેટિવ બેંકની સ્થાપના કરવાનો નિર્ણય",
                img: `${require('../../images/news2.jpg')}`,
                id: 'news2'
            },
            {
                title: "ગુજરાત નિયંત્રી બજાર સંઘ તેજસ ",
                desc: "કેડીસીસી બેંકે અમેરિકન પ્રતિનિધિમંડળ સાથે ચર્ચા કરી પેન્સિલવેનિયામાં ઈન્ડો-અમેરિકન કોઓપરેટિવ બેંકની સ્થાપના કરવાનો નિર્ણય",
                img: `${require('../../images/news3.jpg')}`,
                id: 'news3'
            },
            {
                title: "ખેડા ડિસ્ટ્રિક્ટ સેન્ટ્રલ કો-ઓપરેટિવ બેંક ",
                desc: "કેડીસીસી બેંકે અમેરિકન પ્રતિનિધિમંડળ સાથે ચર્ચા કરી પેન્સિલવેનિયામાં ઈન્ડો-અમેરિકન કોઓપરેટિવ બેંકની સ્થાપના કરવાનો નિર્ણય",
                img: `${require('../../images/news4.jpeg')}`,
                id: 'news4'
            },
        ]
    }


}


const Home = () => {

    const [bgColor, setBgColor] = useState(false)
    const [counterOn, setCounterOn] = useState(false)
    const { language } = useLanguage();
    const [homeData, setHomeData] = useState()
    const [images, setimages] = useState({ img: '', title: '' })
    const [isHovered, setIsHovered] = useState(false);
    const [data,setData] = useState()
    const [galleryImages, setGalleryImages] = useState()
    const [latestNews, setLatestNews] = useState()
    const [showNews, setShowNews] = useState(latestNews?.[0]?.id)


   


    const orange = useRef(null)
    const nocolor = useRef(null)
    const orange2 = useRef(null)
    const nocolor2 = useRef(null)

    useEffect(() => {
        if (language) {
            setHomeData(englishData)
        } else {
            setHomeData(gujratData)
        }
    }, [language])

    const navigate = useNavigate()

    const project = () => {
        let arr = []
        for (let i = 0; i < homeData?.section4?.project?.length; i++) {
            arr.push(
                <div class='projectCart ' onClick={() => navigate(homeData?.section4?.project?.[i]?.url)}>
                    <div class='projectCartImg'>
                        <img src={homeData?.section4?.project?.[i].img} style={{ width: '100%' }} alt="" />
                    </div>
                    <div class='projectCartText bg-white py-3'>
                        <p class='text-black'>{homeData?.section4?.project?.[i].title}</p>
                        <p class='fs-5 fw-bold col-11 m-auto text-black'>{homeData?.section4?.project?.[i].text}</p>
                    </div>
                    <div class='projectCartBtn'>
                        <button class="custom-btn btnBlue" onClick={() => navigate(homeData?.section4?.project?.[i]?.url)} style={{ width: '100%' }}><span>{homeData?.section4?.project?.[i].button}</span></button>
                    </div>
                </div>
            )
        }
        return arr;
    }

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setBgColor(false)
                }
            },
            { rootMargin: '0px', threshold: 0.5 }
        );

        if (nocolor.current) {
            observer.observe(nocolor.current);
        }
        return () => {
            if (nocolor.current) {
                observer.disconnect();
            }
        };
    }, [nocolor]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setBgColor(true)
                }
            },
            { rootMargin: '0px', threshold: 0.5 }
        );

        if (orange.current) {
            observer.observe(orange.current);
        }
        return () => {
            if (orange.current) {
                observer.disconnect();
            }
        };
    }, [orange]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setBgColor(false)
                }
            },
            { rootMargin: '0px', threshold: 0.5 }
        );

        if (nocolor2.current) {
            observer.observe(nocolor2.current);
        }
        return () => {
            if (nocolor2.current) {
                observer.disconnect();
            }
        };
    }, [nocolor2]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setBgColor(true)
                }
            },
            { rootMargin: '0px', threshold: 0.5 }
        );

        if (orange2.current) {
            observer.observe(orange2.current);
        }
        return () => {
            if (orange2.current) {
                observer.disconnect();
            }
        };
    }, [orange2]);

    useEffect(() => {
        if (window) {
            // console.log(window.scrollY);
        }
    }, [window])

    const date = new Date()
    const formatDate = (date) => {
       const year = date.getFullYear();
       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
       const day = String(date.getDate()).padStart(2, '0');
 
       return `${year}${month}${day}`;
    }
 


    const dateFomate = formatDate(date)
    const key = 'KDCC'
    const token = md5(dateFomate + key)
 
    useEffect(() => { 
       axios.post(`https://hi-labsolution.org/tejas_kdcc/public/api/cmspage`, { lang: language ? "en" : "gu" }, { headers: { 'X-Custom-Token': token } })
          .then((res) => {
          
             setData(res?.data?.data)
             setGalleryImages(res?.data?.data?.Gallery)
          })
          .catch((err) => {
            
          })
    }, [language, token])

    const currentYears = new Date().getFullYear();  
    const previousYears = currentYears - 1;   
    
 


    useEffect(()=>{
        data?.PressMedia?.map((el)=>{
            if(el?.title == currentYears ){ 
                setLatestNews(el?.detail)
            } 
        })
    },[data])

    useEffect(()=>{
        setShowNews(latestNews?.[0]?.id)
    },[latestNews])
 







    return (
        <div style={bgColor ? { background: '#EA5136', transition: '0.3s', color: 'white' } : { background: '#F5F2F2', transition: '0.3s', color: '#1d2b77' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='homeTop' >
                <div class='homeTopA' >
                    <div class='col-md-7 col-12  text-start overflow-x-hidden '>
                        <div class=' col-md-12 col-11 p-0 m-auto mt-4 '>
                            <div class='display-3 fw-semibold px-3' >
                                <p data-aos="flip-up" style={{color:"#657C19"}}>{homeData?.section1?.title1}</p>
                                {/* <p data-aos="flip-up"  >{homeData?.section1?.title1}</p> */}

                            </div>
                            <div class='display-3 fw-semibold my-1 px-3 p-1 text-white m-0' style={{ background: '#EA5136' }} data-aos="fade-left">
                                <p class='py-2 m-0' style={{ width: 'fit-content' }}>{homeData?.section1?.title2}</p>
                            </div>
                            {/* <div class='display-3 fw-semibold px-3' >
                                <p data-aos="flip-up">{homeData?.section1?.title3}</p>
                            </div> */}

                            <div class='mt-5'>
                                <Signature />
                            </div>
                            <div class='mbDiv'></div>
                        </div>


                        <div class='col-md-8 col-11  d-flex gap-sm-5 gap-2 flex-wrap justify-content-end' style={{ position: 'absolute', bottom: '3%', right: '3%' }}>
                            <div><a class='con fs-5 fw-semibold' href="tel:+918000335555"> <i class="bi bi-telephone-fill"></i> <span>  (+91) 80003 35555</span></a> </div>
                            <div><a class='con fs-5 fw-semibold' href="mailto:sahkar@tejashpatel.in"> <i class="bi bi-envelope-fill"></i> <span> sahkar@tejashpatel.in</span></a></div>
                        </div>

                    </div>
                </div>

                <div class='d-flex justify-content-end' >
                    <div class='col-lg-5 col-12' style={{ background: 'linear-gradient(to right, #EA5136 60%,#232A50 40%)' }}>
                    </div>
                    <div class='col-lg-7 col-12 text-start row m-0' style={{ background: '#232A50' }}>
                        {
                            homeData?.section2?.map((el) => {
                                return (
                                    <div class='col-md-4 col-11 m-auto homeCart text-center'>
                                        <div class='col-11 m-auto my-4'>
                                            <div class='frem m-auto '>
                                                <div class='innerFrem'>
                                                    <img src={el.icon} style={{ width: '100%' }} alt="" />
                                                </div>
                                                <div class='outerFrem'>
                                                    <img src={require('../../images/outerFrem.png')} style={{ width: '100%' }} alt="" />
                                                </div>
                                            </div>
                                            <div class='my-3 text-white' style={{ height: '50px' }}>
                                                <p class='fs-5 fw-semibold'>{el.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div class='homeTopTejasImg'>
                    <img src={require('../../images/tejasPatel.png')} alt="" />
                </div>
            </div>

            <div>
                <div class='col-lg-8 col-md-10 col-11 m-auto my-5'>
                    <p class='fs-1 d-flex justify-content-center m-0' data-aos="fade-down">{homeData?.section3?.line1} </p>
                    <p class='fs-1 d-flex justify-content-center flex-wrap gap-sm-2 m-0' data-aos="fade-down"> <span class='text-nowrap'> {homeData?.section3?.line2_1}   </span>  <span class='fw-bold text-nowrap' style={{ color: 'red' }}> {homeData?.section3?.line2_2} </span></p>
                    {/* <p class='fs-1 d-flex gap-2 justify-content-center m-0' data-aos="fade-down"> {homeData?.section3?.line3_1}   <span class='fw-bold'> {homeData?.section3?.line3_2} </span>  </p> */}

                    {/* <p class='fs-1 d-flex justify-content-center m-0'><AnimatedText text={homeData?.section3?.line1} time={1000} /></p>
                    <p class='fs-1 d-flex justify-content-center flex-wrap gap-sm-2 m-0'> <span class='text-nowrap'><AnimatedText text={homeData?.section3?.line2_1} time={1500} /> </span>  <span class='fw-bold text-nowrap' style={{ color: 'red' }}><AnimatedText text={homeData?.section3?.line2_2} time={2000} /></span></p>
                    <p class='fs-1 d-flex gap-2 justify-content-center m-0'><AnimatedText text={homeData?.section3?.line3_1} time={2500} /> <span class='fw-bold'><AnimatedText text={homeData?.section3?.line3_2} time={2700} /></span>  </p>
                    <p class='d-flex justify-content-center'> <AnimatedText text={homeData?.section3?.line4} time={3200} /></p> */}
                    <div class='my-4'>
                        <button class="custom-btn btnBlue"><span>{homeData?.section3?.button1}</span></button>
                        <button class="custom-btn btnOrange"><span>{homeData?.section3?.button2}</span></button>
                    </div>
                </div>
            </div>



            <div>
                <div
                    className='col-11 m-auto'
                    style={{
                        height: '55vh',
                        borderRadius: '15px',
                        position: 'relative',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}

                >
                    {isHovered ?
                        <ReactPlayer
                            style={{ borderRadius: '10px', position: 'absolute', top: 0, left: 0 }}
                            width="100%"
                            height="100%"
                            controls={isHovered} // Show controls only when hovered
                            url={'https://youtu.be/0GNAfi72zho?si=0V4ykFB_BzJrxFzR'}
                            playing={isHovered} // Play the video when hovered
                        />
                        :
                        <div class='youtubImge'></div>
                    }



                </div>


            </div>

            <UndrLineText1 text={homeData?.section4?.title} />
            <div ref={nocolor}>
                <div class='m-auto homeProject'>
                    <div class='col-11 m-auto '>
                        <HomeProject data={project()} />
                    </div>
                </div>
            </div>


            <div ref={orange} class='mt-5'>
                <p class='awardTitle' data-aos="fade-down" style={bgColor ? { color: 'rgba(255,255,255, 0.6)', transition: '1s' } : { color: 'rgba(0,0,0, 0.8)', transition: '1s' }}>{homeData?.section5?.title1}</p>
                <p class='awardTitle' data-aos="fade-down" style={bgColor ? { color: 'rgba(255,255,255, 0.6)', transition: '1s' } : { color: 'rgba(0,0,0, 0.8)', transition: '1s' }}>{homeData?.section5?.title2}</p>
            </div>



            <div class='col-11 m-auto mt-5'>
                <TimeLine2 timeLine={homeData?.section5?.timeLine} color={bgColor} />
            </div>

            <div ref={orange2} class='col-11 m-auto text-start mt-3 ' >
                <button class="custom-btn btnBlue" onClick={() => navigate('/achievements')}><span>{homeData?.section5?.button}</span></button>
            </div>

            <div class='projectsCount mt-2'>
                <br />
                <div class='col-11 row m-auto align-items-center justify-content-around bg-white my-3 rounded-5'>

                    {homeData?.section6?.map((el) => {
                        return (

                            <div class='col-lg-3 col-md-6 col-11 p-3 my-4'>
                                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <p class='display-3 fw-bold' style={{ color: '#646FAB' }}><span  >{counterOn && <CountUp start={0} end={el.number} delay={0} />}</span></p>
                                </ScrollTrigger>
                                <p class='text-secondary'>{el.title}</p>
                            </div>
                        )
                    })}

                </div>
                <br />
            </div>

            <div ref={nocolor2}   >
                <UndrLineText2 text={homeData?.section7?.title} />
                <div>
                <ImageGallery imgData={galleryImages}/>
            </div>

                {/* <div>
                    <div class='gallery row  m-0  p-0'>
                        <div class='col-lg-6 row p-0 m-0'>
                            <div class='col-sm-6 p-0'>
                                <div class='galImg' style={{ height: '200px' }}>
                                    <img src={require('../../images/g1.jpg')} onClick={() => setimages({ img: require('../../images/gg1.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                                <div class='row p-0 m-0' style={{ height: '200px' }}>
                                    <div class='col-6 galImg'>
                                        <img src={require('../../images/g2.jpg')} onClick={() => setimages({ img: require('../../images/gg2.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '200px' }} alt="" />
                                    </div>
                                    <div class='col-6 m-0 p-0 galImg '>
                                        <img src={require('../../images/g3.jpg')} onClick={() => setimages({ img: require('../../images/gg3.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '200px' }} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class='col-sm-6 galImg p-0 m-0' style={{ height: '400px' }}>
                                <img src={require('../../images/g4.jpg')} onClick={() => setimages({ img: require('../../images/gg4.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                        </div>
                        <div class='col-lg-6 row p-0 m-0 '>
                            <div class='col-md-6 p-0 m-0'>
                                <div class='row p-0 m-0'>
                                    <div class='col-6 p-0 m-0 galImg' style={{ height: '150px' }}>
                                        <img src={require('../../images/g5.jpg')} onClick={() => setimages({ img: require('../../images/gg5.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                                    </div>
                                    <div class='col-6 p-0 m-0 galImg' style={{ height: '150px' }}>
                                        <img src={require('../../images/g6.jpg')} onClick={() => setimages({ img: require('../../images/gg6.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                                    </div>
                                </div>
                                <div class='row p-0 m-0'>
                                    <div class='col-7 m-0 p-0 galImg' style={{ height: '250px' }}>
                                        <img src={require('../../images/g7.jpg')} onClick={() => setimages({ img: require('../../images/gg7.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                                    </div>
                                    <div class='col-5 p-0 m-0 galImg' style={{ height: '250px' }}>
                                        <img src={require('../../images/g8.jpg')} onClick={() => setimages({ img: require('../../images/gg8.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class='col-md-6 p-0 m-0'>
                                <div class='galImg' style={{ height: '250px' }}>
                                    <img src={require('../../images/g9.jpg')} onClick={() => setimages({ img: require('../../images/gg9.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                                <div class='galImg' style={{ height: '150px' }}>
                                    <img src={require('../../images/g10.jpg')} onClick={() => setimages({ img: require('../../images/gg10.jpg'), title: 'Image Title' })} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    images?.img &&
                    <div class='galleryPopUp'>
                        <div class='col-11 m-auto ' >
                            <div class='d-flex align-items-center justify-content-end'>
                                <i class="bi bi-x-circle text-white fs-1" style={{ cursor: 'pointer' }} onClick={() => setimages('')}></i>
                            </div>
                            <div class='m-auto col-lg-4 col-md-6 col-sm-8 ' >
                                <img src={images?.img} style={{ width: '100%', aspectRatio: '1/1' }} alt="" />
                            </div>
                            <div class='mt-3'>
                                <p class='fw-bold fs-5 text-white'>{images?.title}</p>
                            </div>

                        </div>

                    </div>
                } */}

                <div class='col-11 m-auto text-center mt-5'>
                    <button class="custom-btn btnOrange" onClick={() => navigate('/gallery')}><span>{homeData?.section7?.button}</span></button>
                </div>
            </div>


            <div class='col-11 m-auto text-start mt-5'>
                <UndrLineText3 text={homeData?.section8?.title} />
            </div>

            <div class='col-11 m-auto row'>
                <div class='col-md-6'>
                    {latestNews?.map((el, i) => {
                        return (
                            <div>
                                {showNews == el.id ? <div class='col-10 m-auto'>
                                    <img src={el.image} width='100%' style={{ borderRadius: '15px', maxHeight: '400px' }} alt="" />
                                </div>
                                    : ''
                                }
                            </div>
                        )
                    })}
                </div>
                <div class='col-md-6'>
                    {latestNews?.map((el, i) => {
                        return (
                            <div class=' col-11 m-auto text-start' style={ showNews == el.id  ?  { borderLeft:'3px solid #EA5136' } :{ borderBottom: '1px solid gray' }} onClick={() => navigate('/press')} onMouseEnter={() => setShowNews(el.id)} >
                                <div class='col-sm-11 m-auto my-4 '>

                                <p  style={showNews == el.id ? { color: '#1d2b77', fontWeight: '600', cursor: 'pointer' } : { color:'#1d2b77'}} dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                                {showNews == el.id && 
                                <div class=''>

                                    <p style={showNews == el.id ? { color: '#1d2b77', cursor: 'pointer', marginLeft:'10px', fontSize:'14px' } : {color:'#1d2b77'}} dangerouslySetInnerHTML={{ __html: el?.desc }} ></p>
                                </div>
                                }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

         


            <div class='my-4'> 
                <button class="custom-btn btnOrange" onClick={() => navigate('/press')}><span>{language ? "View All" : "બધુજ જુઓ"}</span></button>
            </div>



            <div class='col-11 m-auto text-start mt-5'>
                <UndrLineText3 text={language ? "Follow" : "અનુસરો"} />
            </div>


            <div class='col-11 m-auto  row gap-md-0 gap-4'>
                <div class='col-md-6'>
                    <div class='col-11 m-auto '>
                        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialtejash&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=false&hide_cover=true&show_facepile=false&appId" height="400" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </div>
                </div>
                <div class='col-md-6'>
                    <div class='col-11 m-auto'>
                        <InstagramEmbed />
                    </div>
                </div>



            </div >
        </div >
    )
}

export default Home


// AIzaSyCgf98UCs89zOZ3CGDinQXbgxIhZ90u3yE