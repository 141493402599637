import React, { useEffect, useState } from 'react';
import { Gallery as GridGallery } from "react-grid-gallery";  // Rename import to avoid conflict
import "react-image-lightbox/style.css";
import './gall.css'

const ImageGallery = ({ imgData = [] }) => {
  const [index, setIndex] = useState(-1);
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (!imgData || imgData.length === 0) {
      return;  // If imgData is undefined or empty, don't proceed
    }
    // Simulating fetching data from API
    const formattedData = imgData?.map(item => ({
      src: item.image,
      thumbnail: item.image,
      width: parseInt(item.width),
      height: parseInt(item.width, 10) * 0.67,
      title: item?.title
    }));

    setImages(formattedData);


  }, [imgData]);


  const currentImage = images?.[index];
  const nextIndex = (index + 1) % images?.length;
  const nextImage = images?.[nextIndex] || currentImage;
  const prevIndex = (index + images?.length - 1) % images?.length;
  const prevImage = images?.[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div>

      <div>
        <GridGallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />

      </div>
      {index !== -1 && (
        <div className="lightbox">
          <div class='d-flex align-items-center justify-content-end col-11 m-auto '>


            <button className="custom-btn btnOrange  px-3 mt-1" onClick={handleClose}>✖</button>
          </div>
          <div className=" row" style={{ height: "90vh" }}>
            <div class='col-1 d-flex align-items-center '>
              <button class="custom-btn btnOrange " onClick={handleMovePrev}><span>⟵ </span></button>


            </div>
            <div class='col-10 d-flex align-items-center'>
              <div class='m-auto'>
                <p class='text-white fw-semibold '>{currentImage?.title}</p>
                <img src={currentImage?.src} alt="Full view" className="full-img mt-2" />
              </div>

            </div>
            <div class='col-1 d-flex align-items-center justify-content-end'>
              <button class="custom-btn btnOrange" onClick={handleMoveNext}><span>⟶ </span></button>
            </div>
          </div>
        </div>
      )}

    </div>

  );
};

export default ImageGallery;
