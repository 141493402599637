import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from './LanguageContext';
import { HomeProject } from './MultiCarousel';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const englishData = {
    section1: {
        title: 'Projects',
    },
    section2: {
        project: [
            {
                title: 'KDCC Bank Project',
                text: "Soaring to New Heights under Tejas Patel's Leadership",
                img: `${require('../images/ps4.jpg')}`,
                url: './kdcc-bank-project',
                button: 'Learn More'
            },
            {
                title: 'Sauraurja',
                text: 'Brings clean energy independence and cost reduction to villages',
                img: `${require('../images/ps1.jpg')}`,
                url: './sauraurja',
                button: 'Learn More'

            },
            // {
            //     title: 'Project Category',
            //     text: 'Help to Sustain by green energy project',
            //     img: `${require('../images/ps3.jpg')}`,
            //     url: './',
            //     button: 'Learn More'

            // },
            // {
            //     title: 'Project Category',
            //     text: 'Help to Sustain by green energy project',
            //     img: `${require('../images/ps4.jpg')}`,
            //     url: './',
            //     button: 'Learn More'

            // },
            // {
            //     title: 'Project Category',
            //     text: 'Help to Sustain by green energy project',
            //     img: `${require('../images/ps2.jpg')}`,
            //     url: './',
            //     button: 'Learn More'

            // },
        ]
    },
}

const gujratData = {
    section1: {
        title: 'પ્રોજેક્ટ્સ',
    },
    section2: {
        project: [
            {
                title: 'કેડીસીસી બેંક',
                text: 'તેજસ પટેલના નેતૃત્વમાં નવી ઊંચાઈઓ સુધી પહોંચે છે',
                img: `${require('../images/ps4.jpg')}`,
                url: './kdcc-bank-project',
                button: 'વધુ જાણો',
            },
            {
                title: 'સૌર ઊર્જા',
                text: 'ગામડાઓમાં સ્વચ્છ ઉર્જા સ્વતંત્રતા અને ખર્ચમાં ઘટાડો લાવે છે',
                img: `${require('../images/ps1.jpg')}`,
                url: './sauraurja',
                button: 'વધુ જાણો',
            },
            // {
            //     title: 'પ્રોજેક્ટ શ્રેણી',
            //     text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
            //     img: `${require('../images/ps3.jpg')}`,
            //     url: './',
            //     button: 'વધુ શીખો',
            // },
            // {
            //     title: 'પ્રોજેક્ટ શ્રેણી',
            //     text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
            //     img: `${require('../images/ps4.jpg')}`,
            //     url: './',
            //     button: 'વધુ શીખો',
            // },
            // {
            //     title: 'પ્રોજેક્ટ શ્રેણી',
            //     text: 'ગ્રીન એનર્જી પ્રોજેક્ટ દ્વારા ટકાવી રાખવામાં મદદ',
            //     img: `${require('../images/ps2.jpg')}`,
            //     url: './',
            //     button: 'વધુ શીખો',
            // },
        ]
    },

}

const Projects = () => {
    const { language } = useLanguage();
    const [projectData, setProjectData] = useState()

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

    useEffect(() => {
        if (language) {
            setProjectData(englishData)
        } else {
            setProjectData(gujratData)
        }
    }, [language])

    const navigate = useNavigate()


    const project = () => {
        let arr = []
        for (let i = 0; i < projectData?.section2?.project?.length; i++) {
            arr.push(
                <div class='projectCart'>
                    <div class='projectCartImg'>
                        <img src={projectData?.section2?.project?.[i].img} style={{ width: '100%' }} alt="" />
                    </div>
                    <div class='projectCartText bg-white py-3'>
                        <p class=''>{projectData?.section2?.project?.[i].title}</p>
                        <p class='fs-5 fw-bold col-11 m-auto'>{projectData?.section2?.project?.[i].text}</p>
                    </div>
                    <div class='projectCartBtn'>
                        <button class="custom-btn btnBlue" onClick={() => navigate('/projects-details')} style={{ width: '100%' }}><span>{projectData?.section2?.project?.[i].button}</span></button>
                    </div>
                </div>
            )
        }
        return arr;
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='orangeBg'>
                <div>
                    <p class='display-1 fw-bold experienceText text-white' data-aos="fade-left" dangerouslySetInnerHTML={{ __html: projectData?.section1?.title }}></p>
                </div>
            </div>

            <div class=''>
                <div class='col-11 m-auto'>
                    <HomeProject data={project()} />
                </div>
            </div>

            <br /><br />

            {/* <div class='my-5'>
                <div class='col-11 m-auto'>
                    <HomeProject data={project()} />
                </div>
            </div> */}
        </div>
    )
}

export default Projects