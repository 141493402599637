import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LanguageProvider } from './components/LanguageContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
  </BrowserRouter>
);
serviceWorkerRegistration.register(); 

reportWebVitals();
