import React, { useEffect, useRef } from 'react'
import '../../App.css'

const UndrLineText3 = ({ text }) => {
  const line = useRef(null)
  useEffect(() => {
    const underline = document.querySelector('.textLineDrow3')
    underline.classList.remove()
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            underline.classList.add('active')
          }, [200])
        } else {
          underline.classList.remove('active')
        }
      },
      { rootMargin: '0px', threshold: 0.5 }
    );
    if (line.current) {
      observer.observe(line.current);
    }
    return () => {
      if (line.current) {
        observer.disconnect();
      }
    };
  }, [line]);
  return (
    <div class='textUnderLine my-5 m-auto' ref={line}>
      <p class='display-3'> {text} </p>
      <p class='display-3 textLineDrow3'>{text}</p>
    </div>

  )
}

export default UndrLineText3