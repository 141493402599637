import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const englishData = {
    section1: {
        title: "About Me",
        text: 'We all do better when we work together. Our differences do matter, but our common humanity matters more.',
        img: `${require('../images/about1.jpg')}`
    },
    section2: {
        text1: "Tejash Patel is a farmer and agricultural leader who has worked tirelessly to improve the lives of farmers in Gujarat, India. He has served as chairman of many organizations, including the Kheda District Central Co-op Bank, the Gujarat State Co Operative Tobacco Growers Federation, and The Petlad-Sojitra Taluka Co-operative Purchase and Sales Union. He is also the founder and chairman of the world’s first solar cooperative.",
    },
    section3: {
        title: "<p>Taste of <span style='color:#EA5136'>Farmers   </span> </p>",
        text: "Tejash Patel is a man with a mission: to improve the lives of farmers in Gujarat, India. He has served as chairman of many organizations that work to support farmers, and he is also the founder and chairman of the world’s first solar cooperative. Through his work, Patel has helped to increase farmers’ incomes, improve their access to resources, and promote sustainable farming practices.",
        img: `${require('../images/about2.jpg')}`
    },
    section4: {
        missiontitle: "MISSON",
        visiontitle: "VISION",
        mission: "To empower farmers and improve their livelihoods.",
        vision: "A prosperous and sustainable future for agriculture in Gujarat."
    },
    section5: {
        title: "<p>Rooted in the land, <span style='color:#EA5136'>passionate </span> about the <span style='color:#EA5136'> farmers future. </span></p>",
        news: [
            {
                title: "Proven Results, Passionate Heart",
                text: "Tejash Patel, Leading Farmers Forward. "
            },
            {
                title: "Understanding Challenges, Delivering Solutions",
                text: "Tejash Patel, Championing a Sustainable Future for Agriculture. "
            },
            {
                title: "Building Bridges, Building Prosperity",
                text: "Tejash Patel, Connecting Farmers to Progress. "
            },
            {
                title: "More than Experience, It's Dedication",
                text: "Tejash Patel, Making a Difference for Farmers, Every Day."
            },
        ]
    },
    section6: {
        title: "Experience",
    },
    section7: {
        title: '<p>Lorem ipsum dolor sit amet, conste <span style="color:red">Unique Selling Points</span></p>',
        cart: [
            {
                title: "Lorem ipsum dolor",
                text: "mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor ",
                background: '#EA5136'
            },
            {
                title: "Lorem ipsum dolor",
                text: "mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor ",
                background: '#727CB6'

            },
            {
                title: "Lorem ipsum dolor",
                text: "mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor ",
                background: '#727CB6'
            },
            {
                title: "Lorem ipsum dolor",
                text: "mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor ",
                background: '#EA5136'
            }
        ]
    }

}

const gujratData = {
    section1: {
        title: "અમારા વિશે",
        text: 'આપણે સૌ જ્યારે સાથે મળીને કોઈપણ કાર્ય સહકારથી કરીએ છીએ ત્યારે તે કાર્ય આપણા સૌ માટે હંમેશા ઉત્તમ જ થાય',
        img: `${require('../images/about1.jpg')}`
    },
    section2: {
        text1: "તેજસ પટેલ ખેડૂત પરિવાર માંથી આવતા સહકારી ક્ષેત્રના અગ્રણી આગેવાન છે. જેઓએ ગુજરાતમાં ખેડૂતોનું જીવનધોરણ ઊંચું લાવવા માટેના અથાગ પ્રયત્નો કર્યા છે. તેઓ ધી ખેડા જિલ્લા મધ્યસ્થ સહકારી બેંક નડીઆદ, ગુજરાત રાજ્ય કો-ઓપરેટિવ ટોબેકો ગ્રોઅર્સ ફેડરેશન તથા ઘી પેટલાદ-સોજીત્રા તાલુકા કો-ઓપરેટિવ પર્ચેઝ એન્ડ સેલ્સ યુનિયન જેવી ઘણી સંસ્થાઓના અધ્યક્ષ છે. સાથો સાથ, તેઓ વિશ્વની પ્રથમ સોલાર સહકારી સંસ્થાના સ્થાપક અને અધ્યક્ષ પણ છે.",
    },
    section3: {
        title: "<p>ટેસ્ટ ઓફ <span style='color:#EA5136'>ખેડૂત </span> </p>",
        text: "તેજસ પટેલ એક એવું વ્યક્તિત્વ છે કે જેઓ ગુજરાતના ખેડૂતોનાં જીવનધોરણમાં પરિવર્તન લાવવાનો નિરંતર પ્રયાસ કરી રહ્યા છે.તેઓએ ખેડૂતોને પગભર કરતી ઘણી સંસ્થાઓમાં અગ્રણી રહીને પોતાની અમૂલ્ય સેવાઓ આપી છે. તેમની ખેડૂત કલ્યાણ માટેની ચળવળ,ખેડૂતો માટે સતત ઝઝૂમતા રહેવાના કારણે ખેડૂતોની આર્થિક પ્રગતિ થઈ છે જેના કારણે ખેડૂતોને જરૂરી સંસાધનો પૂરા પાડવાથી આધુનિક ખેતી પદ્ધતિઓને બહોળું પ્રોત્સાહન મળ્યું છે.",
        img: `${require('../images/about2.jpg')}`
    },
    section4: {
        missiontitle: "મિશન",
        visiontitle: "વિઝન",
        mission: "ખેડૂતોને સશક્ત બનાવવા તથા તેઓના જીવનધોરણને શ્રેષ્ઠ બનાવવું.",
        vision: "ગુજરાતમાં ખેતી માટે સમૃદ્ધ અને ઉજ્જવળ ભવિષ્ય"
    },
    section5: {
        title: "<p>જમીન સાથે જોડાયેલું <span style='color:#727CB6'>જુસ્સાદાર હૃદય. </span></p>",
        news: [
            {
                title: "જમીન સાથે જોડાયેલું જુસ્સાદાર હૃદય.",
                text: "તેજસ પટેલ એક એવું નામ છે જે ગુજરાતના ખેડૂતોના હૃદય સાથે ધબકે છે. ખેતી તેમના માટે માત્ર વ્યવસાય નથી, પણ જુસ્સો અને જીવનશૈલી છે. તેઓ ખેડૂતોની સમસ્યાઓ સમજે છે અને તેમના માટે હંમેશા ઉકેલ શોધવા માટે કટિબદ્ધ રહે છે."
            },
            {
                title: "કાર્યો અને પરિણામ બોલે છે",
                text: "તેજસ પટેલના કાર્યો અને પરિણામો બોલે છે કે તેમણે ખેડૂતોને સહકારી તથા સરકારી યોજનાઓ સાથે જોડવા માટે સેતુ બનાવ્યો છે જેના કારણે ખેડૂતો આધુનિક પદ્ધતિઓ અને ટેકનોલોજીનો ઉપયોગ કરીને વધુ સારી ઉત્પાદન ક્ષમતા મેળવી રહ્યા છે જેથી સમૃદ્ધ બનવાનો માર્ગ વધુ મોકળો બન્યો છે."
            },
            {
                title: "વિશ્વની પ્રથમ સૌર ઊર્જા ઉત્પાદક સહકારી સંસ્થાના સંસ્થાપક",
                text: "તેજસ પટેલ તેજસ પટેલે વિશ્વની પ્રથમ સોલાર ઉર્જા ઉત્પાદક સહકારી સંસ્થાની સ્થાપના કરીને ઇતિહાસ રચ્યો છે. આ સંસ્થા દ્વારા ખેડૂતોને ઓછી કિંમતમાં વીજળી મળી રહે છે, જેના કારણે તેમના ખર્ચમાં ઘટાડો થયો છે અને તેઓ વીજળી વેચીને ઉત્તમ આવક મેળવી રહ્યા છે."
            },
            {
                title: " પરિવર્તન સાથે ચાલતા ખેડૂત નેતા",
                text: " તેજસ પટેલ પરિવર્તન સાથે ચાલતા ખેડૂત નેતા છે. તેઓ નવી ટેકનોલોજી અને આધુનિક પદ્ધતિઓને અપનાવવા માટે ખેડૂતોને પ્રોત્સાહન અપી રહ્યા છે. તેઓના માર્ગદર્શન હેઠળ, ગુજરાતના ખેડૂતોએ 21મી સદીમાં ખેતી ક્ષેત્રે  વિશાળ હરણફાળ ભરી છે."
            },
        ]
    },
    section6: {
        title: "અનુભવ",
    },
    section7: {
        title: '<p>પીડા પોતે જ મહત્વપૂર્ણ છે, વસ્તીની <span style="color:red"> અનન્ય વેચાણ બિંદુઓ</span></p>',
        cart: [
            {
                title: "ખુબ ખુબ આભાર",
                text: "પીડા પોતે જ મહત્વપૂર્ણ છે, વસ્તીની સ્થૂળતા બંધ થઈ જશે, પરંતુ જો પીડા સમાન હોય તો તે જ વસ્તુ થાય છે, વસ્તીની સ્થૂળતા અનુસરશે ",
                background: '#EA5136'
            },
            {
                title: "ખુબ ખુબ આભાર",
                text: "પીડા પોતે જ મહત્વપૂર્ણ છે, વસ્તીની સ્થૂળતા બંધ થઈ જશે, પરંતુ જો પીડા સમાન હોય તો તે જ વસ્તુ થાય છે, વસ્તીની સ્થૂળતા અનુસરશે ",
                background: '#727CB6'

            },
            {
                title: "ખુબ ખુબ આભાર",
                text: "પીડા પોતે જ મહત્વપૂર્ણ છે, વસ્તીની સ્થૂળતા બંધ થઈ જશે, પરંતુ જો પીડા સમાન હોય તો તે જ વસ્તુ થાય છે, વસ્તીની સ્થૂળતા અનુસરશે ",
                background: '#727CB6'

            },
            {
                title: "ખુબ ખુબ આભાર",
                text: "પીડા પોતે જ મહત્વપૂર્ણ છે, વસ્તીની સ્થૂળતા બંધ થઈ જશે, પરંતુ જો પીડા સમાન હોય તો તે જ વસ્તુ થાય છે, વસ્તીની સ્થૂળતા અનુસરશે ",
                background: '#EA5136'

            }
        ]
    }

}

const About = () => {
    const { language } = useLanguage();
    const [aboutData, setAboutData] = useState()

    useEffect(() => {
        if (language) {
            setAboutData(englishData)
        } else {
            setAboutData(gujratData)
        }
    }, [language])

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })


    return (
        <div style={{ background: "#F5F2F2", overflow: 'hidden' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='aboutTop' >
                <div class='col-lg-4 col-md-6 col-10 ' style={{ position: 'absolute', bottom: '5%', overflow: 'hidden' }}>
                    <div class='col-12' style={{ background: '#EA5136' }} data-aos="fade-right">
                        <p class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{aboutData?.section1?.title}</p>
                    </div>
                    <div class='col-10 text-start mt-3 text-white' style={{ marginLeft: '10%' }} data-aos="fade-right">
                        <p class='fs-5 justifyT'>{aboutData?.section1?.text}</p>
                    </div>
                </div>
            </div>

            <div style={{ background: `url(${require('../images/aboutbg2.png')})`, backgroundSize: '50% 100%', backgroundPosition: 'right top', backgroundRepeat: 'no-repeat' }}>
                <br />
                <div class='col-11 m-auto text-start'>
                    <div class='col-lg-5 col-md-8 text-start'>
                        <p class='fs-5 mt-4 justifyT' dangerouslySetInnerHTML={{ __html: aboutData?.section2?.text1 }} data-aos="fade-right"></p>
                        <p class='fs-5 mt-4 justifyT' dangerouslySetInnerHTML={{ __html: aboutData?.section2?.text2 }} data-aos="fade-right"></p>
                        <p class='fs-5 mt-4 justifyT' dangerouslySetInnerHTML={{ __html: aboutData?.section2?.text3 }} data-aos="fade-right"></p>
                    </div>
                </div>
            </div>

            <div>
                <div class='col-11 m-auto row my-5 gap-lg-0 gap-5'>
                    <div class='col-lg-5 text-start'>
                        <div data-aos="fade-right">
                            <strong class='fs-1 mt-4' dangerouslySetInnerHTML={{ __html: aboutData?.section3?.title }} ></strong>
                        </div>
                        <p class='fs-5 mt-4 justifyT' dangerouslySetInnerHTML={{ __html: aboutData?.section3?.text }} data-aos="fade-right"></p>
                    </div>
                    <div class='col-lg-7'>
                        <div class='col-lg-11 col-md-8   m-auto'>
                            <img data-aos="flip-right " src={aboutData?.section3?.img} style={{ height: '400px', width: '100%', objectFit: 'cover', borderRadius: '15px' }} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div class='bgd d-flex align-items-center justify-content-center' style={{ background: `url(${require('../images/aboutbg3.png')})` }}>
                <div class='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '100%', background: 'rgba(255,255,255,0.1)' }}>
                    <div class='aboutMission my-5 ' data-aos="zoom-in">
                        <div>
                            {/* <div class='d-flex justify-content-center'>
                                <p class='p-1 px-2 text-white fw-bold' style={{ background: 'red', width: 'fit-content', borderRadius: '15px' }} dangerouslySetInnerHTML={{ __html: aboutData?.section4?.title }}></p>
                            </div> */}
                            <div class='col-11 m-auto mt-4 row justify-content-center'>
                                <div class='col-6  ' style={{ borderRight: '1px solid #FF0000' }}>
                                    <p class='p-1 px-2 text-white fw-bold m-auto' style={{ background: 'red', width: 'fit-content', borderRadius: '15px' }} dangerouslySetInnerHTML={{ __html: aboutData?.section4?.missiontitle }}></p>

                                    <p class=' mt-3 ' dangerouslySetInnerHTML={{ __html: aboutData?.section4?.mission }}></p>
                                </div>
                                <div class='col-6' style={{ borderLeft: '1px solid #FF0000' }}>
                                    <p class='p-1 px-2 text-white fw-bold m-auto' style={{ background: 'red', width: 'fit-content', borderRadius: '15px' }} dangerouslySetInnerHTML={{ __html: aboutData?.section4?.visiontitle }}></p>

                                    <p class=' mt-3' dangerouslySetInnerHTML={{ __html: aboutData?.section4?.vision }}></p>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div>
                <br />
                <div class='col-11 m-auto row '>
                    <div class='col-md-6 text-start'>
                        <div class='col-sm-10 mt-5' data-aos="fade-right">
                            <strong class='fs-1 ' dangerouslySetInnerHTML={{ __html: aboutData?.section5?.title }}></strong>
                        </div>
                    </div>
                    <div class='col-md-6'>
                        <div className="newsmarquee-container mt-5">
                            <div className="newsmarquee">
                                {
                                    aboutData?.section5?.news?.map((el) => {
                                        return (
                                            <div class='text-start my-2' style={{ borderLeft: '5px solid #EA5136' }}>
                                                <p class='col-sm-10 m-auto my-2  fs-5 text-black fw-bold' style={{ color: '#1d2b77 !important' }}>{el.title}</p>
                                                <p class='col-sm-10 m-auto my-2 news text-black'>{el.text}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {/* <div class='d-flex align-items-center justify-content-end my-5'>
                <p class='display-1 fw-bold experienceText' data-aos="fade-left" dangerouslySetInnerHTML={{ __html: aboutData?.section6?.title }}></p>
            </div> */}
            <br />
            {/* <div>
                <div class='col-11 m-auto row flex-column-reverse flex-lg-row gap-lg-0 gap-4 my-5'>
                    <div class='col-lg-6 row m-0'>
                        {aboutData?.section7?.cart?.map((el) => {
                            return (
                                <div class='col-sm-6' data-aos="fade-right" style={{ background: `${el.background}` }}>
                                    <div class='col-11 m-auto my-4 text-start text-white'>
                                        <div style={{ width: '35px', height: '35px' }}>
                                            <img src={require('../images/aboutIcon.png')} style={{ width: '100%' }} alt="" />
                                        </div>
                                        <p class='my-2 fw-bold fs-5'>{el.title}</p>
                                        <p>{el.text}</p>

                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    <div class='col-lg-6'>
                        <div class=' col-11 m-auto text-start' data-aos="fade-left">
                            <strong class='fs-1' dangerouslySetInnerHTML={{ __html: aboutData?.section7?.title }}></strong>
                        </div>
                    </div>
                </div>
                <br />
            </div> */}
        </div>
    )
}

export default About