import React, { useEffect, useState } from 'react';
import './navbar.css'
import { useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext';


const Navbar = () => {
    const [isActive, setIsActive] = useState(false);
    const { language, toggleLanguage } = useLanguage();
    const navigate = useNavigate()

    useEffect(() => {
        const nav = document.querySelector('.navbar');
        const logo = document.querySelector('.navLogo')
        logo.style.opacity = "0"


        let lastScrollY = window.scrollY;
        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                nav.classList.add("navColor");
                logo.style.opacity = "1"
            } else {
                nav.classList.remove("navColor");
                logo.style.opacity = "0"
            }
        })
    })

    useEffect(() => {
        const nav = document.querySelector('.menus')
        if (isActive) {
            nav.classList.add('active')
        } else {
            nav.classList.remove('active')
        }
    }, [isActive])


    const chengpath = (el) => {
        navigate(el)
        setIsActive(false)
    }


    return (
        <div>
            <div class='navbar' >
                <nav class='col-11 m-auto d-flex align-items-center justify-content-between '>
                    <div class=''>
                        <img class='navLogo' src={require('../images/signatureNav.png')} onClick={()=>navigate('/')} alt="" />
                    </div>
                    <div class='d-flex gap-5 align-items-center'>
                        <div class='d-flex align-items-center '>
                            <div class='row align-items-center language'>
                                <div style={language ? { background: '#EA5136', color: 'white' } : {color:"#1d2344"}} class='col-6 english p-1 py-0' onClick={() => toggleLanguage()}>En</div>
                                <div style={!language ? { background: '#EA5136', color: 'white' } : {color:"#1d2344"}} class='col-6 gujrati p-1 py-0' onClick={() => toggleLanguage()}>ગુજ</div>
                            </div>
                        </div>
                        <div>
                            <div class={isActive ? 'hamburger is-active' : 'hamburger'} id="hamburger" onClick={() => setIsActive(!isActive)} >
                                <span class="line"></span>
                                <span class="line"></span>
                                <span class="line"></span>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            <div class='menus'>

                <div class=''>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/')}>{language ? <span>Home</span> : <span>હોમ</span>}</p>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/about-us')}>{language ? <span>About Me</span> : <span>મારો પરિચય </span>}</p>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/achievements')}>{language ? <span>Achivement</span> : <span>સિદ્ધિઓ</span>}</p>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/projects')}>{language ? <span>Projects</span> : <span>પ્રોજેક્ટ્સ</span>}</p>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/initiatives')}>{language ? <span>Initiatives</span> : <span>મારી પહેલ</span>}</p>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/press')}>{language ? <span>Press</span> : <span>પ્રેસ</span>}</p>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/gallery')}>{language ? <span>Gallery</span> : <span>ગેલેરી</span>}</p>
                    <p class='fs-2 fw-bold' style={{cursor:'pointer'}} onClick={() => chengpath('/contact')}>{language ? <span>Contact Us</span> : <span>સંપર્ક કરો</span>}</p>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
