import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from './LanguageContext';
import UndrLineText3 from './underline/UndrLineText3';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import axios from 'axios';

const englishData = {
    section1: {
        title: "Contact Us"
    },
    section2: {
        title: "<span style='color:#EA5136'>Contact</span>  with us",
        address: 'Tejash B Patel (Jigabhai), Shreenivas, Pipdav,Ta-Sojitra, Di-Anand, Gujarat, India , 388460',
        phone: '(+91) 9825045742',
        mail: 'sahkar@tejashpatel.in'
    },
    section3: {
        title: 'Contact us',
        // text: 'Currently He is an APMC-Chairman in Petlad, Chairman of KDCC Bank,Nadiad. Director of Gujcomarsol, Ahmedabad. Chairman of Petlad-Sojitra Taluka Sahkari Kharid Vechan Shang and RGB Delegate Krubhko,Delhi.',
        img: `${require('../images/g10.jpg')}`
    },
    section4: {
        titleFirstName: "First Name",
        titleLastName: "Last Name",
        titleEamil: "Email",
        titlePhone: "Phone number",
        titleReason: "Reason",
        titleSelectState: "Select State",
        titleCityName: "City Name",
        titleMessage: "Message",
        button: "Submit",
    },
    section5: {
        title: 'Tell us what you feel...',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt uLorem ipsum dolor s',
        button: 'Contact us'
    }
}

const gujratData = {
    section1: {
        title: "અમારો સંપર્ક કરો"
    },
    section2: {
        title: "<span style='color:#EA5136'>સંપર્ક કરો</span>  અમારી સાથે",
        address: 'તેજશ બી પટેલ (જીગાભાઈ), શ્રીનિવાસ, પીપડાવ, તા-સોજીત્રા, દી-આણંદ, ગુજરાત, ભારત, 388460',
        phone: '(+91) 9825045742',
        mail: 'sahkar@tejashpatel.in'
    },
    section3: {
        title: 'અમારો સંપર્ક કરો',
        // text: 'હાલમાં તેઓ પેટલાદમાં APMC-ચેરમેન, KDCC બેંક, નડિયાદના ચેરમેન છે. અમદાવાદના ગુજકોમરસોલના ડાયરેક્ટર. પેટલાદ-સોજીત્રા તાલુકા સહકારી ખરીદ વેચન શાંગના અધ્યક્ષ અને આરજીબી ડેલીગેટ ક્રુભકો, દિલ્હી.',
        img: `${require('../images/g10.jpg')}`
    },
    section4: {
        titleFirstName: "પ્રથમ નામ",
        titleLastName: "છેલ્લું નામ",
        titleEamil: "ઈમેલ",
        titlePhone: "ફોન નંબર",
        titleReason: "કારણ",
        titleSelectState: "રાજ્ય પસંદ કરો",
        titleCityName: "શહેરનું નામ",
        titleMessage: "સંદેશ",
        button: "સબમિટ કરો",
    },
    section5: {
        title: 'તમને શું લાગે છે તે અમને જણાવો...',
        text: 'પીડા પોતે જ મહત્વપૂર્ણ છે, એડિપીસિંગ અનુસરવામાં આવશે, પરંતુ તે જ સમયે તે પીડામાં પડી જાય છે.',
        button: 'અમારો સંપર્ક કરો'
    }
}

const Contact = () => {

    const { language } = useLanguage();
    const [contactData, setContactData] = useState()
    const [contact,setContact]= useState({})



    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

    useEffect(() => {
        if (language) {
            setContactData(englishData)
        } else {
            setContactData(gujratData)
        }
    }, [language])

    const contactInfo = (e)=>{
        const {name,value}= e.target;
        setContact({...contact,[name]:value})
    }

    
    const sendInfo =()=>{
        if(contact?.name && contact?.name != "" &&
        contact?.lastname && contact?.lastname != "" &&
        contact?.email && contact?.email != "" &&
        contact?.phone && contact?.phone != "" &&
        contact?.city && contact?.city != "" &&
        contact?.message && contact?.message != "" && 
        contact?.reason && contact?.reason != ""){
       
                axios.post(`https://hi-labsolution.net/api/tejaspatel-contact.php`,contact)
                .then((res)=>{
                    // console.log(res.data.responseMessage);
                    if(res.data.responseStatus == true){
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: `${res.data.responseMessage}`,
                          showConfirmButton: false,
                        //   timer: 2000,
                        })
                        setContact({
                            "name" : '',
                            'lastname':'',
                            'email': '',
                            'phone':'',
                            "city" : '',
                            'reason':'',
                            'message': '',
                            
                        })
                    } 
                }).catch((err)=>{
                    // console.log(err);
                }) 
        }else{
            Swal.fire({
                position: "center",
                icon: "error",
                title: `Fill all filds`,
                showConfirmButton: false,
                timer: 2000,
              })
        }
    }

    // console.log(contact);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='orangeBg' >
                <div>
                    <p class='display-1 fw-bold experienceText text-white' data-aos="zoom-in" dangerouslySetInnerHTML={{ __html: contactData?.section1?.title }}></p>
                </div>
            </div>


            <div class='col-11 m-auto row my-5 gap-md-0 gap-5'>
                <div class='col-md-5 text-start'>
                    <p class='fs-1 fw-bold mt-3'>{contactData?.section3?.title}</p>
                    <p class='my-3'>{contactData?.section3?.text}</p>
                    <img src={contactData?.section3?.img} width={"100%"} class='rounded-4' alt="" />
                </div>
                <div class='col-md-7 bg-white rounded-4'>
                    <br />
                    <div class='row m-0 p-0'>
                        <div class='col-md-6 text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titleFirstName}</p>
                            <input type="text" class='form-control mt-1' placeholder={contactData?.section4?.titleFirstName} value={contact?.name} name='name' onChange={contactInfo} />
                        </div>
                        <div class='col-md-6 text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titleLastName}</p>
                            <input type="text" class='form-control mt-1' placeholder={contactData?.section4?.titleLastName} value={contact?.lastname} name='lastname'  onChange={contactInfo}/>
                        </div>

                        <div class='col-md-6 text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titleEamil}</p>
                            <input type="text" class='form-control mt-1' placeholder={contactData?.section4?.titleEamil} value={contact?.email} name='email'  onChange={contactInfo}/>
                        </div>

                        <div class='col-md-6 text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titlePhone}</p>
                            <input type="text" class='form-control mt-1' placeholder={contactData?.section4?.titlePhone} value={contact?.phone} name='phone' onChange={contactInfo} />
                        </div>

                        <div class='col-md-6 text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titleCityName}</p>
                            <input type="text" class='form-control mt-1' placeholder={contactData?.section4?.titleCityName}  value={contact?.city} name='city' onChange={contactInfo} />
                        </div>

                        <div class='col-md-6 text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titleReason}</p>
                            <input type="text" class='form-control mt-1' placeholder={contactData?.section4?.titleReason} value={contact?.reason} name='reason' onChange={contactInfo}/>
                        </div>


                        {/* <div class='  text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titleReason}</p>
                            <input type="text" class='form-control mt-1' placeholder={contactData?.section4?.titleReason} />
                        </div> */}
                        <div class='text-start mt-3'>
                            <p class='fw-bold'>{contactData?.section4?.titleMessage}</p>
                            <textarea class='form-control mt-1' placeholder={contactData?.section4?.titleMessage} value={contact?.message} name='message' onChange={contactInfo}></textarea>
                        </div>
                        <div class='d-flex justify-content-end my-3'>
                            <button class="custom-btn btnBlue" onClick={sendInfo}><span>{contactData?.section4?.button}</span></button>
                        </div>
                    </div>
                    <br />
                </div>
            </div>

            <div class='position-relative' style={{ height: '80vh' }}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7370.840598045857!2d72.76625213844902!3d22.525923128432044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e542678cd17b9%3A0xb52c161ee000e923!2sTejash%20Patel!5e0!3m2!1sen!2sin!4v1708751827850!5m2!1sen!2sin" width="100%" height="100%" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div class='col-lg-4 col-md-6 col-11 position-absolute bg-white rounded-4 text-start' style={{ top: '50px', right: '5%' }}>
                    <br />
                    <div class='col-11 m-auto'>
                        <p class='display-4 fw-bold' dangerouslySetInnerHTML={{ __html: contactData?.section2?.title }}></p>
                        <div class='d-flex gap-3 mt-3 '>
                            <p class='mt-1' style={{ color: '#EA5136' }}><i class="bi bi-geo-alt-fill"></i></p>
                            <p>{contactData?.section2?.address}</p>
                        </div>

                        <div class='d-flex gap-3 mt-2'>
                            <p style={{ color: '#EA5136' }}><i class="bi bi-telephone-fill"></i></p>
                            <p>{contactData?.section2?.phone}</p>
                        </div>

                        <div class='d-flex gap-3 mt-2 '>
                            <p style={{ color: '#EA5136' }}><i class="bi bi-envelope-fill"></i></p>
                            <p>{contactData?.section2?.mail}</p>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <br />


            <div class='col-11 m-auto rounded-4 text-white mt-4' style={{ background: '#727CB6' }}>
                <br />
                <div class='col-11 m-auto row align-items-center my-3 '>
                    <div class=' col-md-9 text-start'>
                        <p class='fs-1 fw-bold text-white'>{contactData?.section5?.title}</p>
                        {/* <p >{contactData?.section5?.text}</p> */}


                    </div>
                    <div class='col-md-3 d-flex justify-content-end'>
                        <button class="custom-btn btnOrange"><span>{contactData?.section5?.button}</span></button>
                    </div>
                </div>
                <br />

            </div>

        </div>
    )
}

export default Contact